<template>
    <!-- header -->
   <div class="display-flex flex-col" id="CommentSection">
        <span v-if="commentListInfo.totalComments>0">{{$t('ALL_COMMENTS')}} ({{commentListInfo.totalComments}})</span>
        <span v-else>{{$t('NO_COMMENT')}}</span>
        <span class="divider mt-m mb-1rem"></span>
    </div>
    
    <!-- first comment list -->
    <div v-if="commentListInfo.totalComments>0 && !isLogin" class="relative comment-first__wrapper">
          <CommentCard  :key="index" :dataList="firstCommentList" class="mb-1rem" :isShowFavReply="false"></CommentCard>
          <div class="comment-blur"></div>
    </div>

    <!-- login to comment -->
    <div v-if="!isLogin" class="comment-container flex-col mb-1rem " :class="{'comment-login__wrapper': commentListInfo.totalComments>0}">
        <div class="mb-05rem" v-if="commentListInfo.totalComments>0">{{$t('TO_POST_OR_LIKE_A_COMMENT')}}</div>
        <div class="mb-05rem" v-else>{{$t('SIGNUP/LOGIN_TO_POST_A_COMMENT')}}</div>
        <div class="nav-pill__wrapper pb-0">
            <router-link :to="{ name: 'signin' , query: { callBackUrl: currentPathName }}"  class="nav-pill">{{$t('LOGIN')}}</router-link>
            <router-link :to="{ name: 'signup' , query: { callBackUrl: currentPathName }}"  class="nav-pill">{{$t('SIGNUP')}}</router-link>
        </div>
    </div>

    <div v-else>
        <CommentReply class="mb-1rem"></CommentReply>
        <div v-if="commentListInfo.totalComments>0">
            <div v-for="(o, index) in commentList" :key="index" class="comment-wrapper">
                <CommentCard  :dataList="o" :parentData="o" class="mb-1rem" ></CommentCard>
               
                <div class="comment-child__wrapper mb-1rem">
                    <!-- show child reply count -->
                     <div v-if="o.childCommentListingRes.remainingCommentCount>0" @click="showChildComment(o)" class="pointer display-flex-align-center mb-05rem">
                        <img class="mr-05rem" src="../../../static/images/icons/icon_reply.png">
                        <div class="comment-child__reply-count">
                            <span class="mr-05rem">{{o.childCommentListingRes.remainingCommentCount}}</span>
                            <span v-if="o.childCommentListingRes.remainingCommentCount>1">{{$t('REPLIES')}}</span>
                            <span v-else>{{$t('REPLY')}}</span>
                        </div>
                    </div>
                </div>

                <!-- child comment -->
                <div v-if="o.isShowChildComment" class="comment-child__wrapper">
                      <div v-if="o.childHasNext" class="pointer more-comments__child"  @click="showMoreChildComment(o)">{{$t('MORE_COMMENTS')}}</div>
                      <CommentCard v-for="(x, index) in o.child" :key="index" :dataList="x"  class="mb-1rem" :isShowReply="false"></CommentCard>
                      <CommentReply class="mb-1rem" :parentData="o"></CommentReply>
                </div>
            </div>
            <div v-if="comment.hasNext" class="pointer font-m" @click="getMoreComments()">{{$t('MORE_COMMENTS')}}</div>
        </div>
    </div>
</template>

<script>
import CommentCard from '@/components/comment/Card.vue'
import CommentReply from '@/components/comment/Reply.vue'
import config from '@/js/config.js'
import {mapGetters,mapActions} from 'vuex'
export default {
    components: {
        CommentCard,
        CommentReply
    },
    props: {
        isGetData: {
            type: Boolean
        }
    },
    watch:{
		isGetData: {
            deep: true,
            handler(newVal,oldVal) {    
                 this.init();			
            }
        }
    },
    data() {
        return {    
            currentPathName: '',  
            comment: {
                totalComments: 0,
                hasNext: false
            }, 
            pageSizeComment: config.pageSizeComment,
            pageSizeChildComment: config.pageSizeChildComment,
            // firstCommentList: {}

          
        }           
    },
	watch:{
		$route() {
            if (this.$route.name === 'newsDetail') {
                this.init();               
            } 
        },
	},
    computed:{
        ...mapGetters([              
            'commentList',
            'isLogin',
            'commentListInfo',
            'firstCommentList'
        ]),
    },

    mounted() {
        this.init();
    },

    methods: {
          ...mapActions([
            "commentListEvent",
            "getNewsListComment",
            "commentListChildEvent",
            "commentListInfoEvent"
        ]),
        
        init() {  
           this.newsId = this.$route.params.id;
           this.currentPathName = window.location.pathname;
           this.getCommentListData();
        },
          

        async getCommentListData() {
            let params = {
                newsId: this.newsId,
                size: this.pageSizeComment
            }
           
            const result = await this.getNewsListComment(params);
            let returnResult = result.result;

            this.comment.hasNext = returnResult.hasNext;

            if (returnResult.totalComments>0) {
               //this.firstCommentList = returnResult.comments[0];
            }
     
            this.commentListEvent(returnResult);           
        },

        async getMoreComments() {
            let lastComment = this.commentList[this.commentList.length-1];
     
            let params = {
                newsId: this.newsId,
                lastCommentId: lastComment.newsCommentId,
                size: this.pageSizeComment
            }

            const result = await this.getNewsListComment(params);

            let returnResult = result.result;
            this.comment.hasNext = returnResult.hasNext;

            returnResult.comments.isMore = true;
     
            this.commentListEvent(returnResult);  
        },

        async showChildComment(o) {
            o.isShowChildComment = !o.isShowChildComment;
        
            
            if (o.isShowChildComment) {
                let params = {
                    newsId: this.newsId,
                    size: this.pageSizeChildComment,
                    newsCommentParentId: o.newsCommentId
                }

                const result = await this.getNewsListComment(params);
                
                let returnResult = result.result;
                returnResult.newsCommentParentId = o.newsCommentId;

                this.commentListChildEvent(returnResult); 
            }           
        },

        async showMoreChildComment(o) {
            let lastCommentId = o.child[0].newsCommentId;

            let params = {
                newsId: this.newsId,
                size: this.pageSizeChildComment,
                newsCommentParentId: o.newsCommentId,
                lastCommentId: lastCommentId,
            }

            const result = await this.getNewsListComment(params);

            let returnResult = result.result;
            returnResult.newsCommentParentId = o.newsCommentId;

            returnResult.isShowPreviousComment = true;

            this.commentListChildEvent(returnResult); 
        }
    },

}
</script>

<style>
.comment-blur{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    /* height: 5.5rem; */
    background-image: linear-gradient(to bottom, transparent, var(--bg-theme));
    z-index: 2;
}
.comment-first__wrapper{
    height: 6rem;
}
.comment-login__wrapper{
    position: relative;
    top: -2rem;
    z-index: 2;
    margin-left: 1rem;
}
.comment-child__wrapper{
    margin-left: 3rem;
}
.comment-child__reply-count{
    color: var(--color-blue-20);
    border-bottom: 1px solid var(--color-blue-20);
    display: inline-block;
    cursor: pointer;
}
.more-comments__child{
    font-size: 0.8875rem;
    margin-bottom: 1rem;
    text-align: center;
}

</style>